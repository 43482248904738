header{
    background-image: url('../../assets/zebra.jpg');
    height: 100vh;
    background-size: cover;
    
    overflow: hidden;
    
}

.header__container {
    text-align: center;
    height: 100%;
    position: relative;
}

.header__name-background{
    background: var(--color-primary);
    max-width: 50%;
    min-height: 47%;
    height: 47%;
    top: 0;
    padding-top: 7rem;
    border-radius: 0 0 7rem 7rem;
  
}

/* CTA */
.cta {
    margin-top: 2.5rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center;
}

.cta__btn {
    width: max-content;
    display: inline-block;
    color: var(--color-white);
    padding: 0.75rem 1.2rem;
    border-radius: 0.4rem;
    cursor: pointer;
    border: 1px solid var(--color-white);
    transition: var(--transition);
}
/* SOCIALS */

.header__socials {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8 rem;
    position: absolute;
    left: 0;
    bottom: 3rem;
    color: var(--color-white);
}

.header-icon{
    color: var(--color-white);
}

.header__socials::after {
    content: '';
    width: 1px;
    height: 2rem;
    background: var(--color-white);
}

/* IMAGE */
.me{
    background: linear-gradient(var(--color-primary), transparent);
    width: 22rem;
    height: 30rem;
    position: absolute;
    left: calc(50% - 11rem);
    margin-top: 4rem;
    border-radius: 12rem 12rem 0 0;
    overflow: hidden;
    padding: 5rem 1.5rem 1.5rem 1.5rem;
}

/*Scroll*/
.scroll__down{
    position: absolute;
    right:  -2.3rem;
    bottom: 5rem;
    transform: rotate(90deg);
    font-weight: 300;
    font-size: 0.9rem;
    color: var(--color-white);
}

@media screen and (max-width: 1024px) {
    .header {
        height: 68vh;
    }
}

/* Media Queries(Small Devices) */
@media screen and (max-width: 600px) {
    header {
        height: 100vh;
    }
    .header__socials,
    .scroll__down {
        display: none;
    }
    .header__name-background {
        max-width: 70%
    }
    .cta__btn {
        
        color: white;
    }
    .cta{
        padding-top: 20px;
    }
    .text-light {
        display: none;
    }
}